/* eslint-disable no-undef */
import AppConfig from 'Constants/AppConfig'
import lodash from 'lodash'
import { removeCountryFromAddress } from '../helpers/string'

class GoogleMaps {
  constructor () {
    if (typeof google !== 'undefined' && google !== null) {
      this.googleApiKey = AppConfig.googleApiKey
      const map = new google.maps.Map(document.createElement('div'))
      this.sessionToken = new google.maps.places.AutocompleteSessionToken()
      this.autocompleteService = new google.maps.places.AutocompleteService()
      this.placeService = new google.maps.places.PlacesService(map)
      this.geocoder = new google.maps.Geocoder()
      this.map = map

      this.directionsService = new google.maps.DirectionsService()
      this.directionsRenderer = new google.maps.DirectionsRenderer()
      this.directionsRenderer.setMap(map)
      this.distanceMatrixService = new google.maps.DistanceMatrixService()
      this.locationBias = new google.maps.Circle({
        center: { lat: 52.100453, lng: 5.6461006 },
        // eslint-disable-next-line comma-dangle
        radius: 150000,
      }).getBounds()
    }
  }

  getCurrentLocation () {
    return new Promise((resolve) => {
      if (!navigator.geolocation) {
        resolve('')
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }

          this.geocoder.geocode({ location: pos }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                resolve({
                  address: removeCountryFromAddress(results[0].formatted_address),
                  location: pos
                })
              }
            }
          })
        }
      )
    })
  }

  formatPlaceAddress (addressComponents) {
    let route = '' // type route
    let streetNumber = '' // type street_number
    let city = '' // type administrative_area_level_2
    let country = '' // type country

    addressComponents.forEach(addressComponent => {
      if (addressComponent.types.includes('route')) {
        route = addressComponent.long_name
      }

      if (addressComponent.types.includes('street_number')) {
        streetNumber = addressComponent.long_name
      }

      if (addressComponent.types.includes('administrative_area_level_2')) {
        city = addressComponent.long_name
      }

      if (addressComponent.types.includes('country')) {
        country = addressComponent.long_name
      }
    })

    return `${route} ${streetNumber} ${route || streetNumber ? ', ' : ''} ${city} ${country ? ', ' : ''} ${country}`
  }

  getFirstAddressFromGooglePlace (value) {
    const request = {
      input: value || ' ',
      language: 'nl',
      sessionToken: this.sessionToken,
      locationBias: this.locationBias,
      // eslint-disable-next-line comma-dangle
      strictBounds: false,
      // componentRestrictions: { country: ['nl', 'be'] }
    }

    return new Promise(resolve => {
      this.autocompleteService.getPlacePredictions(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placeDetailsRequest = {
            sessionToken: this.sessionToken,
            placeId: results[0].place_id,
            fields: ['adr_address', 'name', 'formatted_address', 'geometry.location', 'address_components']
          }

          this.placeService.getDetails(placeDetailsRequest, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const address = place.formatted_address.indexOf(place.name) > -1 ? place.formatted_address : `${place.name}, ${place.formatted_address}`
              const locality = place.address_components.find(addressComponentItem => addressComponentItem.types && addressComponentItem.types.includes('locality'))

              const city = locality ? locality.long_name : ''

              resolve({
                address,
                city,
                data: place,
                location: {
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng()
                }
              })
            } else {
              resolve(null)
            }
          })
        } else {
          resolve(null)
        }
      })
    })
  }

  getAddressesFromGooglePlaces ({ address, successCB, errorCB } = {}) {
    const request = {
      input: address || ' ',
      language: 'nl',
      sessionToken: this.sessionToken,
      locationBias: this.locationBias,
      // eslint-disable-next-line comma-dangle
      strictBounds: false,
      // componentRestrictions: { country: ['nl', 'be'] }
    }

    this.autocompleteService.getPlacePredictions(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        successCB(results)
      } else {
        errorCB({ results, status })
      }
    })
  }

  async getAddressesByPlaceIds (placeIds) {
    const addresses = []
    const predictionsPromises = placeIds.map(placeId => new Promise((resolve) => {
      const placeDetailsRequest = {
        sessionToken: this.sessionToken,
        placeId: placeId,
        fields: ['adr_address', 'name', 'formatted_address', 'geometry.location', 'address_components']
      }

      this.placeService.getDetails(placeDetailsRequest, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const address = place.formatted_address.indexOf(place.name) > -1 ? place.formatted_address : `${place.name}, ${place.formatted_address}`
          const locality = place.address_components.find(addressComponentItem => addressComponentItem.types && addressComponentItem.types.includes('locality'))

          const city = locality ? locality.long_name : ''

          addresses.push({
            address: removeCountryFromAddress(address),
            city,
            data: place,
            location: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            }
          })

          resolve()
        }
      })
    }), () => {}, () => { })

    await Promise.all(predictionsPromises)
    return addresses
  }

  getLongitudeDelta (bounds) {
    return bounds.getNorthEast().lng() - bounds.getSouthWest().lng()
  }

  textSearch (value) {
    return new Promise(resolve => {
      const request = {
        query: value
      }

      this.placeService.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(results[0])
        }

        resolve(null)
      })
    })
  }

  getGoogleStaticMapUrl (stops) {
    // eslint-disable-next-line promise/param-names
    return new Promise(resolveMain => {
      const mapStyle = 'style=feature%3Awater%7Celement%3Ageometry%7Ccolor%3A0xe9e9e9%7Clightness%3A17%7C&style=feature%3Alandscape%7Celement%3Ageometry%7Ccolor%3A0xf5f5f5%7Clightness%3A20%7C&style=feature%3Aroad.highway%7Celement%3Ageometry.fill%7Ccolor%3A0xffffff%7Clightness%3A17%7C&style=feature%3Aroad.highway%7Celement%3Ageometry.stroke%7Ccolor%3A0xffffff%7Clightness%3A29%7Cweight%3A0.2%7C&style=feature%3Aroad.arterial%7Celement%3Ageometry%7Ccolor%3A0xffffff%7Clightness%3A18%7C&style=feature%3Aroad.local%7Celement%3Ageometry%7Ccolor%3A0xffffff%7Clightness%3A16%7C&style=feature%3Apoi%7Celement%3Ageometry%7Ccolor%3A0xf5f5f5%7Clightness%3A21%7C&style=feature%3Apoi.park%7Celement%3Ageometry%7Ccolor%3A0xdedede%7Clightness%3A21%7C&style=feature%3Aall%7Celement%3Alabels.text.stroke%7Cvisibility%3Aon%7Ccolor%3A0xffffff%7Clightness%3A16%7C&style=feature%3Aall%7Celement%3Alabels.text.fill%7Ccolor%3A0x333333%7C&style=feature%3Aall%7Celement%3Alabels.icon%7Cvisibility%3Aoff%7C&style=feature%3Atransit%7Celement%3Ageometry%7Ccolor%3A0xf2f2f2%7Clightness%3A19%7C&style=feature%3Aadministrative%7Celement%3Ageometry.fill%7Ccolor%3A0xfefefe%7Clightness%3A20%7C&style=feature%3Aadministrative%7Celement%3Ageometry.stroke%7Ccolor%3A0xfefefe%7Clightness%3A17%7Cweight%3A1.2%7C'
      const stopFiltered = stops.filter(stop => stop && stop.address && stop.data && !lodash.isArray(stop.data))
      const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'
      const size = '1280x1280'
      const stopsData = []

      if (!stopFiltered.length) {
        resolveMain({})
      }

      let markersUrl = ''
      let path = 'weight:3%7Ccolor:deeppink%7Cenc:'

      stopFiltered.forEach((stop, index) => {
        const currentPlaceIndex = index + 1

        const lat = typeof stop.data.geometry.location.lat === 'function' ? stop.data.geometry.location.lat() : stop.data.geometry.location.lat
        const lng = typeof stop.data.geometry.location.lng === 'function' ? stop.data.geometry.location.lng() : stop.data.geometry.location.lng

        markersUrl += `&markers=color:blue%7Clabel:${currentPlaceIndex}%7C${lat},${lng}`

        const address = stop.data.formatted_address.indexOf(stop.data.name) > -1
          ? stop.data.formatted_address
          : `${stop.data.name}, ${stop.data.formatted_address}`

        stopsData.push({
          ...stop,
          address: removeCountryFromAddress(address)
        })
      })

      this.calculateAndDisplayRoute(stopFiltered.map(item => item.data.formatted_address), (response) => {
        const encodeString = google.maps.geometry.encoding.encodePath(response.routes[0].overview_path)

        path += encodeURI(encodeString)

        const zoom = Math.round(Math.log(360 / this.getLongitudeDelta(response.routes[0].bounds)) / Math.LN2) - 1

        resolveMain({
          url: `${baseUrl}?zoom=${zoom}&size=${size}${markersUrl}&${mapStyle}&key=${this.googleApiKey}&path=${path}&scale=2`,
          stopsData
        })
      })
    })
  }

  calculateAndDisplayRoute (stops, callback) {
    if (!stops.length) {
      return
    }

    const waypts = stops.length > 2 ? stops.slice(1, -1).map(stop => ({
      location: stop,
      stopover: true
    })) : []

    this.directionsService.route(
      {
        origin: stops[0],
        destination: stops[stops.length - 1],
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (response, status) => {
        if (status === 'OK') {
          callback(response)
        }
      }
    )
  }

  getDistanceBetweenAddresses (origin, destination, departureTime, trafficModel = 'bestguess', callback, error) {
    this.distanceMatrixService.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
        drivingOptions: {
          departureTime: new Date(departureTime || Date.now()),
          trafficModel
        }
      }, (response, status) => {
        if (status === 'OK') {
          callback(response)
        } else {
          error()
        }
      })
  }
}

export const googleMaps = new GoogleMaps()
