<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-combobox
    ref="address"
    :items="addressEntries"
    :loading="addressLoading"
    :label="label"
    v-model="addressProp"
    :menu-props="{ 'value': (addressEntries.length > 0 && showMenu), 'return-value': 'address', 'maxHeight': '240px', 'contentClass': 'v-list-medium' }"
    :return-object="false"
    :clearable="showMenu || !$vuetify.breakpoint.xsOnly"
    append-icon=""
    item-text="address"
    item-value="place_id"
    no-filter
    no-data-text
    @change="(e) => onChangeInput(e)"
    @focus="(e) => onFocus(e)"
    @blur="(e) => onBlur(e)"
    @input.native="val => autocomplete(val.srcElement.value)"
    @click:clear="clearInput()"
  />
</template>

<script>
import { Datetime } from 'vue-datetime'
import Request from '../../helpers/request'
import { googleMaps } from '../../helpers/google'
import SmartTimeout from 'smart-timeout'

const timeout = SmartTimeout

export default {
  components: { 'datetime': Datetime },
  mixins: [Request],
  props: {
    address: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Address'
    }
  },
  data () {
    return {
      addressEntries: [],
      addressLoading: false,
      showMenu: false,
      search: null,
      openMenuBlock: false,
      addressProp: this.address
    }
  },
  watch: {
    addressProp: {
      handler (val) {
        this.$emit('onChange', val)
      },
      deep: true
    }
  },
  methods: {
    async onChangeInput (placeId) {
      if (placeId && this.addressEntries.map(item => item.place_id).includes(placeId)) {
        await this.setStopByPlaceId(placeId)
      }
      this.openMenu(false)
    },
    async setStopByPlaceId (placeId) {
      const res = await googleMaps.getAddressesByPlaceIds([placeId])
      if (res && res.length) {
        this.addressProp = res[0]
      }
    },
    onBlur (e) {
      if (e && e.srcElement !== document.activeElement) {
        this.openMenu(false)
      }
    },
    onFocus (e) {
      if (e) {
        this.autocomplete(this.addressProp || '')
        this.openMenu()
      }
    },
    clearInput () {
      this.openMenuBlock = true
      setTimeout(() => { this.openMenuBlock = false }, 15)
    },
    openMenu (close = true, blur = false) {
      if (!this.openMenuBlock) {
        setTimeout(() => {
          this.showMenu = close

          if (!close && !blur) {
            this.$refs['address'].blur()
            this.$vuetify.breakpoint.xsOnly && this.$store.dispatch('setToolbar', !close)
          }
        }, 5)
        // this.$vuetify.goTo(0)
      }
    },
    autocomplete (val, noLoader = false) {
      let body = {
        params: {
          search: val
        }
      }

      !noLoader && (this.addressLoading = true)

      this.openMenu()

      timeout.clear('requestTimer')
      timeout.set('requestTimer', () => {
        this.request('GET', '/stops/address-search', body, ({ data }) => {
          const neededAddressesCount = 10

          if (neededAddressesCount > 0) {
            googleMaps.getAddressesFromGooglePlaces({ address: val,
              successCB: (googleApiAddresses) => {
                const filteredPredictions = googleApiAddresses ? googleApiAddresses
                  .filter(pred => {
                  // Need to exclude similar addresses in future interations
                    return pred
                  })
                  .slice(0, neededAddressesCount) : []

                this.addressEntries = filteredPredictions.map(item => ({ address: item.address || item.description || '', place_id: item.place_id || '' }))
                !noLoader && (this.addressLoading = false)
              },
              errorCB: () => {
                this.addressEntries = data.data.filter(item => item.address)
                !noLoader && (this.addressLoading = false)
              } })
          } else {
            this.addressEntries = data.data.filter(item => item.address)
            !noLoader && (this.addressLoading = false)
          }
        })
      }, 350)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
