const removeCountryFromAddress = (address) => {
  let _address = address

  if (!_address) {
    return ''
  }

  const isNL = Boolean(address.match(/(Nederland|Netherlands)/))

  if (isNL) {
    // Remove country and post code from address
    const postalCodeNLRege = /^\d{4} ?[a-z]{2} $/ig
    const splittedAddress = _address.split(',')

    const updatedSplittedAddress = splittedAddress.map(addressItem => {
      const replacedPostalCode = addressItem.trim().slice(0, 8).replace(postalCodeNLRege, '')

      if (replacedPostalCode.length) {
        return addressItem.trim().replace('Nederland', '').replace('Netherlands', '')
      }

      return `${replacedPostalCode}${addressItem.slice(9)}`.replace('Nederland', '').replace('Netherlands', '')
    })

    _address = updatedSplittedAddress.join(', ').trim()
  }

  return _address[_address.length - 1] === ',' ? _address.slice(0, -1) : _address
}

export { removeCountryFromAddress }
